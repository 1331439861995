<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="text">
            <div class="title big">
              {{ $t('Gear Up for Glory') }}
            </div>
            <router-link 
              to="/product-list"
              class="button"
            >
              <span>{{ $t('Buy TF2 items') }}</span>
            </router-link>
          </div>
          <div class="img-container">
            <img 
              src="./../images/heroImg.png" 
              class="img"
            >
          </div>
        </div>
      </div>
      <div class="section home-products-section">
        <div class="wrapper">
          <div
            v-if="$parent.classOptions && $parent.classOptions.length"
            class="title"
          >
            {{ $t('Build Your Perfect Loadout') }}
          </div>
          <SlickItem 
            v-if="$parent.classOptions && $parent.classOptions.length"
            :currency="currency"
            :is-auth="$parent.isAuth"
            :categories="$parent.classOptions"
            :list="$parent.classOptions" 
            @setProductCategory="setProductCategory"
          />
        </div>
      </div>
      <div class="section how-it-works-section">
        <div class="wrapper">
          <div class="title">
            {{ $t('How It Works') }}
          </div>
          <div class="list">
            <div class="item">
              <img 
                src="./../images/howItWorks/1.svg" 
                class="img"
              >
              <div class="title small">
                {{ $t('Browse Items') }}
              </div>
              <div class="desc">
                {{ $t('Use our search or navigation tools to find the TF2 items you want to purchase.') }}
              </div>
            </div>
            <div class="item">
              <img 
                src="./../images/howItWorks/2.svg"  
                class="img"
              >
              <div class="title small">
                {{ $t('Add to Cart') }}
              </div>
              <div class="desc">
                {{ $t('Add your selected items to your cart and proceed to checkout.') }}
              </div>
            </div>
            <div class="item">
              <img 
                src="./../images/howItWorks/3.svg"  
                class="img"
              >
              <div class="title small">
                {{ $t('Receive Items') }}
              </div>
              <div class="desc">
                {{ $t('Once payment is confirmed, your items will be delivered to your in-game inventory.') }}
              </div>
            </div>
            <div class="item">
              <img 
                src="./../images/howItWorks/4.svg"  
                class="img"
              >
              <div class="title small">
                {{ $t('Happy Gaming!') }}
              </div>
              <div class="desc">
                {{ $t('Equip your new items in Team Fortress 2 and enjoy the enhanced gameplay experience.') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section join-section">
        <div class="wrapper">
          <div class="title">
            {{ $t('Explore') }}
          </div>
          <div class="desc">
            {{ $t('vast selection of TF2 items, including hats, weapons, taunts, and more.') }}
          </div>
          <router-link 
            to="/product-list"
            class="button white" 
          >
            <span>Buy TF2 items</span>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import SlickItem from '../components/SlickItem.vue';

export default {
  name: 'Home',
  components: {
    SlickItem
  },
  props: {
    addToCartChosenItem: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      imgDomain: '',
      list: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(id) {
      this.$emit('addToCart', id)
    },
  }
}
</script>