<template>
  <main 
    v-if="$parent.isAuth"
    class="main profile-page page-inside" 
  >
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="page-inside-top">
          <div class="wrapper">
            <div class="title">
              <router-link 
                to="/" 
                class="desc back"
              >
                {{ $t('Back to main') }}
              </router-link>
              {{ $t('Profile') }}
            </div>
          </div>
        </div>
        <div class="page-inside-wrapper">
          <div class="wrapper">
            <div class="nav-container">
              <div class="nav">
                <router-link 
                  to="/profile/settings" 
                  class="item"
                >
                  <div class="desc">
                    <b>{{ $t('Settings') }}</b>
                  </div>
                </router-link>
                <router-link 
                  to="/profile/orders" 
                  class="item"
                >
                  <div class="desc">
                    <b>{{ $t('Orders') }}</b>
                  </div>
                </router-link>
                <router-link 
                  to="/profile/transactions" 
                  class="item"
                >
                  <div class="desc">
                    <b>{{ $t('Transactions') }}</b>
                  </div>
                </router-link>
                <div 
                  class="item" 
                  @click="$parent.logout"
                >
                  <div class="desc">
                    <b>{{ $t('Log out') }}</b>
                  </div>
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/settings'"
              class="profile-content profile-content-setings"
            >
              <div class="profile-content-left">
                <div class="info">
                  <img 
                    src="./../images/avatar.svg" 
                    class="img"
                  >
                  <div class="text">
                    <div class="desc">
                      <b>{{ name }} {{ surname }}</b>
                    </div>
                    <div 
                      class="desc" 
                      v-text="email"
                    />
                  </div>
                  <div class="desc main-desc">
                    <b>{{ $t('My balance') }}</b>
                  </div>
                  <div class="balance">
                    <div class="left">
                      <img 
                        src="./../images/wallet.svg" 
                        class="img"
                      >
                      <div class="desc big">
                        <b>
                          <span class="currency">{{ $parent.currency }}</span>
                          {{ balance }}
                        </b>
                      </div>
                    </div>
                    <button 
                      class="button blue" 
                      @click="$parent.openTopup"
                    >
                      <span>{{ $t('Top Up') }}</span>
                    </button>
                  </div>
                </div>
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Change password') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Current password') }}
                    </div>
                    <input 
                      v-model="curPass"
                      type="text" 
                      placeholder="Current password" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('New password') }}
                    </div>
                    <input  
                      v-model="newPass"
                      type="text" 
                      placeholder="New password"
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Confirm new password') }}
                    </div>
                    <input 
                      v-model="repeatPass"
                      type="text" 
                      placeholder="Confirm password" 
                    >
                  </div>
                  <button 
                    class="button blue" 
                    @click="updatePassword"
                  >
                    <span>{{ $t('Save') }}</span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="passUpdateErrorMessaage"
                        class="desc red"
                      >
                        {{ passUpdateErrorMessaage }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="passUpdateSuccessMessaage"
                        class="desc green"
                      >
                        {{ passUpdateSuccessMessaage }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
              <div class="profile-content-right">
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Personal Information') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('First name') }}
                    </div>
                    <input 
                      v-model="name"
                      type="text" 
                      placeholder="First name" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Last name') }}
                    </div>
                    <input 
                      v-model="surname"
                      type="text" 
                      placeholder="Last name" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('E-mail') }}
                    </div>
                    <input
                      v-model="email"
                      type="email" 
                      placeholder="E-mail" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Phone') }}
                    </div>
                    <input 
                      v-model="phone"
                      type="tel" 
                      placeholder="Phone" 
                    >
                  </div>
                </div>
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Billing address') }}</b>
                  </div>
                  <div 
                    v-if="countryOptions"
                    class="input-container" 
                  >
                    <div class="desc">
                      {{ $t('Country') }}
                    </div>
                    <select v-model="selectedCountry">
                      <option 
                        value="" 
                        disabled 
                        selected
                      >
                        {{ $t('Countries') }}  
                      </option>
                      <option 
                        v-for="item in countryOptions" 
                        :key="item.id"
                        :value="item"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('City') }}
                    </div>
                    <input 
                      v-model="city"
                      type="text" 
                      placeholder="City" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('ZIP Code') }}
                    </div>
                    <input 
                      v-model="zipCode"
                      type="text" 
                      placeholder="ZIP Code" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Address') }}
                    </div>
                    <input 
                      v-model="address"
                      type="text" 
                      placeholder="Address" 
                    >
                  </div>
                  <button 
                    class="button blue" 
                    @click="updateProfileData"
                  >
                    <span>
                      {{ $t('Save') }}
                    </span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateErrorMessaage"
                        class="desc red" 
                      >
                        {{ profileUpdateErrorMessaage }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateSuccessMessaage"
                        class="desc green" 
                      >
                        {{ profileUpdateSuccessMessaage }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/transactions'"
              class="profile-content profile-content-transactions"
            >
              <div class="desc main-desc">
                <b>{{ $t('Payment hystory') }}</b>
              </div>
              <div 
                v-if="$parent.depositHistory"
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>ID</td>
                          <td>Date</td>
                          <td>Type</td>
                          <td>Status</td>
                          <td>Total</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.depositHistory" 
                          :key="i"
                        >
                          <td>{{ item.item_id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>{{ $t('Top up balance') }}</td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ item.status }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ item.status }}</span>
                          </td>
                          <td v-else>
                            <span>{{ item.status }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination" 
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
            <div 
              v-if="$route.path == '/profile/orders'"
              class="profile-content profile-content-orders"
            >
              <div class="desc main-desc">
                <b>{{ $t('Order history') }}</b>
              </div>
              <div 
                v-if="$parent.orderHistory" 
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>ID</td>
                          <td>Date</td>
                          <td>Skin name</td>
                          <td>Count</td>
                          <td>Status</td>
                          <td>Total</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.orderHistory" 
                          :key="i"
                        >
                          <td>{{ item.item_id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>
                            <img 
                              :src="$parent.imgDomain + item.img_url" 
                              class="img"
                            >
                            {{ item.title }}
                          </td>
                          <td class="desc">
                            {{ item.count }}
                          </td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ item.status }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ item.status }}</span>
                          </td>
                          <td v-else>
                            <span>{{ item.status }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination"
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  components: {
  },
  props: {
    isAuth: {
			type: Boolean,
			required: true
		},
    appBalance: {
			type: [Number, String],
			required: true
		}
  },
  data: function() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      country: '',
      selectedCountry: '',
      countryId: '',
      city: '',
      address: '',
      zipCode: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      balance: '0.00',
      countryOptions: []
    }
  },
  watch: {
		appBalance() {
      this.getProfile();
		},
	},
  mounted() {
    this.getCounties();
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.getProfile();
      })
      .catch((res) => {
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.balance = res.data.balance;
          this.$emit('setAppBalance', res.data.balance);
          this.city = res.data.city;
          this.zipCode = res.data.zip;
          this.address = res.data.address;
          let countryId = this.countryOptions.find(obj => obj.id == res.data.country);
          this.selectedCountry = countryId;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "country": this.selectedCountry.id,
        "city": this.city,
        "postCode": this.zipCode,
        "address": this.address
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
  }
}
</script>